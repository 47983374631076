@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --category-items-count-color: #{$grey3};
    --filter-button-mobile-radius: 0;
}

.CategoryPage {
    &-ItemsCount {
        margin-bottom: 5px;
    }

    &-Filter {
        @include mobileAndTablet {
            padding: 15px 21px;
            margin-top: 7px;
            text-transform: uppercase;
        }
    }

    &-LayoutButtons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 36px;
        @include tablet {
            margin-left: auto;
            margin-top: 15px;
        }

        @include mobile {
            display: flex;
            width: 100%;
            margin-left: -12px;
            padding-left: 0;
            margin-top: 26px;
        }
    }

    &-Miscellaneous {
        @include mobileAndTablet {
            flex-wrap: wrap;

            .CategoryPagination {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }

    &-ProductListWrapper {
        @include mobile {
            padding-top: 46px;
        }
    }

    &-Filter svg {
        @include mobileAndTablet {
            width: 20px;
            height: 20px;
            fill: $white;
        }
    }
}
