@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --category-bellow-heading-color: #{$default-primary-base-color};
    --category-additional-description-color: #{$grey-dark};
}

.CategoryDetailsBellow {
    @include desktop {
        margin-top: 60px;
    }

    @include tablet {
        margin-top: 100px;
    }

    @include mobile {
        margin: 80px 20px;
    }

    &-Heading {
        color: var(--category-bellow-heading-color);
        font-size: 22px;
        line-height: 30px;

        @include desktop {
            margin-bottom: 22px;
        }

        @include tablet {
            margin-bottom: 40px;
        }

        @include mobile {
            margin-bottom: 30px;
        }
    }

    &-AdditionalDescription {
        p,
        span {
            font-size: 15px;
            line-height: 22px;
            color: var(--category-additional-description-color);
        }
    }
}
