@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --category-tree-heading-color: #{$default-primary-base-color};
    --category-tree-heading-font: #{$font-primary};
    --category-tree-category-color: #{$default-primary-base-color};
    --category-tree-category-accent-color: #{$default-secondary-base-color};
    --category-tree-back-color: #{$grey-dark};
    --category-tree-back-font: #{$font-secondary};
    --category-tree-border: 1px solid #e7e7e7;
    --category-tree-radius: 10px;
}

.CategoryTree {
    &.ExpandableContent {
        .ExpandableContent-Heading {
            @include desktop {
                color: var(--category-tree-heading-color);
                font-family: var(--category-tree-heading-font);
                font-weight: bold;
                font-size: 22px;
                line-height: 135%;
                letter-spacing: 0.07em;
                margin-bottom: 12px;
            }
        }
    }

    .ExpandableContent-Content {
        @include desktop {
            margin-top: 0;
        }
    }

    &-Categories {
        border-radius: var(--category-tree-radius);
        border: var(--category-tree-border);
        padding: 15px 30px;

        @include desktop {
            margin-top: 12px;
        }
        @include mobileAndTablet {
            padding: 0;
            border: 0;
        }
        li {
            margin: 0;
        }
    }
    &-Category {
        font-family: var(--category-tree-back-font);
        font-weight: normal;
        font-size: 13px;
        margin: 7px 0;
        cursor: pointer;
        letter-spacing: 1px;
        color: var(--category-tree-category-color);
        position: relative;
        text-align: left;

        @include desktop {
            display: inline-block;
            margin: 10px 0;
        }

        &:before {
            content: '';
            width: 3px;
            height: 12px;
            background: transparent;
            border-radius: 10px;
            position: absolute;
            top: 50%;
            left: -11px;
            transform: translateY(-50%);
        }
        &:hover {
            font-weight: bold;
            &:before {
                background: var(--category-tree-category-accent-color);
            }
        }
    }
    &-BackButton {
        margin: 10px 0 20px 7px;
        button {
            font-family: var(--category-tree-back-font);
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            color: var(--category-tree-back-color);
            cursor: pointer;
        }
        svg {
            width: 12px;
            height: 12px;
            margin-right: 15px;
            path {
                stroke: var(--category-tree-back-color);
            }
        }
    }
}
