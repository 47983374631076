$white: #ffffff;
$grey10: #f9f9f9;
$grey20: #f3f3f3;
$grey30: #e7e7e7;
$grey40: #d1d1d1;
$grey50: #a1a1a1;
$grey60: #8a8989;
$grey70: #797979;
$grey80: #484848;
$black: #000000;
$purple: #f4f3fd;
$purple5: #eae8fd;
$purple10: #eae8fd;
$purple20: #d9d0fc;
$purple30: #af9ef0;
$purple40: #8b77da;
$purple50: #5b45b1;
$purple60: #513ba7;
$purple70: #47309e;
$purple80: #3e2b88;
$purple90: #2b177a;
$blue5: #ccdbe9;
$blue10: #b2c9de;
$blue20: #99b7d4;
$blue30: #6693be;
$blue40: #336fa9;
$blue50: #004b93;
$blue60: #003970;
$blue70: #002d57;
$blue80: #00274c;
$blue90: #001c38;
$orange5: #ffebdc;
$orange10: #ffe6d5;
$orange20: #ffdec6;
$orange30: #ffd3b4;
$orange40: #ffc49a;
$orange50: #e65724;
$orange60: #ec9254;
$orange70: #de7732;
$orange80: #be6329;
$orange90: #9b5529;
$green5: #daedd0;
$green10: #c7e4b8;
$green20: #b5dca1;
$green30: #8fca73;
$green40: #6ab944;
$green50: #45a715;
$green60: #3b8d12;
$green70: #30740e;
$green80: #22510a;
$green90: #173806;
$red5: #ffdddd;
$red10: #ffd2d2;
$red20: #ffc3c3;
$red30: #ffafaf;
$red40: #ff9595;
$red50: #ff5959;
$red60: #eb4e4e;
$red70: #dd2d2d;
$red80: #ba2828;
$red90: #982828;
$red-light: #ffdddd;
$red-light2: #ff7171;
$red: #dd2d2d;
$success-background-color: $green5;
$success-color: $green70;
$error-background-color: $red-light;
$error-color: $red;
$default-primary-base-color: $blue50;
$default-primary-dark-color: $blue60;
$default-primary-light-color: $blue40;
$default-secondary-base-color: $green50;
$default-secondary-dark-color: $green60;
$default-secondary-light-color: $green40;
$default-tertiary-base-color: $green50;
$default-tertiary-dark-color: $green60;
$default-tertiary-light-color: $green40;
$grey-dark: #a1a1a1;
$grey: #aaafb6;
$grey2: #e0e3e6;
$grey3: #797979;
$grey4: #d1d1d1;
$grey5: #e7e7e7;
$grey6: #c4c4c4;
$grey7: #8a8989;
$grey-light: #f3f3f3;
$grey-lightest: #edf0f3;
$font-muli: 'Switzer', sans-serif;
$font-standard-size: 62.5%;
$font-primary: 'Switzer', sans-serif;
$font-secondary: 'Switzer', sans-serif;
