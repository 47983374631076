@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --category-items-count-color: #{$grey-dark};
    --filter-button-mobile-background: #{$default-primary-base-color};
    --filter-button-mobile-color: #{$white};
    --filter-button-mobile-radius: 5px;
    --category-cms-block-border-radius: 10px;
}
.CategoryPage {
    @include mobile {
        margin-top: 0;
    }

    &_noResults {
        min-height: unset;
    }

    &-Wrapper {
        @include desktop {
            margin-top: 0;
        }

        @include from-tablet {
            margin-bottom: 100px;
        }

        @include mobileAndTablet {
            display: grid;
            grid-template-columns: 100%;
        }

        @include mobile {
            margin-top: 0;
            margin-bottom: 80px;
        }
    }

    &-Filter {
        @include mobileAndTablet {
            font-size: 13px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0.7px;
            color: var(--filter-button-mobile-color);
            background: var(--filter-button-mobile-background);
            padding: 11px 21px;
            border-radius: var(--filter-button-mobile-radius);

            .CategoryPage-Subheading {
                font-size: 13px;
                font-weight: 400;
            }

            svg {
                width: 16px;
                height: 18px;
                margin-left: auto;
            }
        }

        @include tablet {
            min-width: 163px;
        }

        @include mobile {
            width: 50%;
            max-width: 130px;
        }
    }

    &-Miscellaneous {
        z-index: 5;
        @include desktop {
            padding-block-end: 30px;
        }

        @include tablet {
            justify-content: flex-start;
        }

        @include mobile {
            position: static;
            padding: 0 16px;
            border-bottom: none;
            flex-wrap: wrap;
        }

        &_noResults {
            padding: 0;
        }

        > nav {
            @include mobile {
                width: 100%;
                padding-inline-start: 0;
                padding-inline-end: 0;
            }
        }

        .CategoryPagination {
            margin-top: 0;
        }

        .CategoryPage-LayoutWrapper {
            @include tablet {
                margin-right: 25px;
            }

            @include mobile {
                padding: 0;
            }
        }
    }

    &-ItemsCount {
        margin: 0;
        color: var(--category-items-count-color);
        font-family: $font-primary;

        @include from-tablet {
            font-size: 15px;
            line-height: 21px;
        }

        @include mobile {
            font-size: 12px;
            line-height: 18px;
            padding: 0;
        }
    }

    &-ProductListWrapper {
        nav {
            @include from-tablet {
                display: block;
                width: max-content;
                margin-left: auto;
            }
        }

        @include desktop {
            margin-top: 30px;
        }

        @include mobileAndTablet {
            margin-top: 32px;
        }
    }

    &-CMS {
        border-radius: var(--category-cms-block-border-radius);
        overflow: hidden;
        line-height: 0;
        margin-top: 0;

        @include desktop {
            margin-block-end: 20px;
        }

        @include mobileAndTablet {
            grid-row-start: 1;
        }

        @include tablet {
            margin-bottom: 32px;
        }

        @include mobile {
            padding: 0;
            margin-bottom: 24px;
        }
    }

    &-LayoutWrapper {
        @include mobile {
            max-width: 50%;
        }
    }
}

.hideOnScroll {
    .CategoryPage-Miscellaneous {
        @include mobile {
            transform: none;
        }
    }
}
